<template>
  <div id="MainCryptosList">
    <div class="row title">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h2 class="h4 d-flex justify-content-between align-items-center mb-0 py-3 font-size-125 font-family-krona">
              <span>Main Crytocurrencies ({{cryptosList.length}})</span>
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div class="row MainCryptosList-list">
      <!--<div class="col-12 mb-3">
        <list-filters @filter="filter($event)"/>
      </div>-->
      <div class="col-12">
        <div class="card">
          <div class="card-body py-0 px-3">
            <!--<div class="align-items-center d-flex">
              <p class="mb-0">Showing {{from}}-{{to}} of {{totalItems}} cryptocurrencies</p>
            </div>-->
            <list :cryptosList="cryptosList"/>
            <!--<div class="row" v-if="numberPages > 1">
              <div class="col-12">
                <pagination :currentPage=currentPage :numberPages=numberPages @selectPage="selectPage($event)" />
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import List from './templates/List.vue';
//import ListFilters from './templates/ListFilters.vue';
//import Pagination from './templates/Pagination.vue';


export default {
  name: 'MainCryptosList',
  data() {
    return {
      cryptosList: [],
      itemsPerPage: 10,
      currentPage: 0,
      totalItems: 0,
      numberPages: 0,
      from: 0,
      to: 0,
    };
  },
  components: {
    List
  },
  mounted() {

    let chainListService = {
      "BSC":"BSC",
      "BNB":"BSC",
      "ETHER":"ETHEREUM",
      "ETH":"ETHEREUM",
      "GOERLI":"GOERLI",
      "SEPHOLIA":"SEPHOLIA",
      "POLYGON":"POLYGON",
      "MUMBAI":"MUMBAI",
      "AVALANCHE":"AVALANCHE",
      "AVAX":"AVALANCHE",
      "FANTOM":"FANTOM",
      "CRONOS":"CRONOS",
      "PALM":"PALM",
      "ARBITRUM":"ARBITRUM",
      "ARB":"ARBITRUM",
      "OPTIMISM":"OPTIMISM",
      "RONIN":"RONIN",
      "CHILIZ":"CHILIZ",
      "GNOSIS":"GNOSIS"
    }

    axios.get("/cryptos/contracts")
      .then(async (response)=> {
        this.$emit('widgetLoaded');
        this.cryptosList = response.data.body;

        let bodyRequest = [];
        for(let i=0; i < this.cryptosList.length; i++){
          /*await this.$getSymbolPrice(this.cryptosList[i].symbol,this.cryptosList[i].source_price, i, this.cryptosList[i].id_coinmarketcap)
            .then((price) => {
              this.cryptosList[i]['price'] = price;
            })
          */
          bodyRequest.push({
            "address": this.cryptosList[i].contracts ? this.cryptosList[i].contracts[0].id : '0',
            "symbol": this.cryptosList[i].symbol,
            "chain": this.cryptosList[i].contracts ? chainListService[this.cryptosList[i].contracts[0].chain.toUpperCase()] : '0',
            "idCoinmarketcap": this.cryptosList[i].id_coinmarketcap ? this.cryptosList[i].id_coinmarketcap : '',
            "id" : this.cryptosList[i].ID
          });
        }
        axios.post(`/v2/tokens/priceByGroup`,bodyRequest)
        .then(async (response)=> {
          console.log(response.data);
          
          for(let i=0; i < this.cryptosList.length; i++){
            this.cryptosList[i]['price'] = response.data.body[i].priceUSD;
          }
        })
      })
  },
  methods: {

  },
};
</script>

<style lang="scss">
  #MainCryptosList{
    .title{
      .card{
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  .MainCryptosList-list{
    .card{
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
  }
</style>
